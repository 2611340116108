<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
    <div class="section">
      <h2>Devenir exposant</h2>
    </div>
    <v-row>
      <v-col cols="12" v-html="text"/>
      <v-col cols="12">
        <v-row>
          <iframe
              :src="url"
              v-if="url"
              width="100%"
              height="1000px"
          >Chargement en cours...
          </iframe>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Global_paramsService from "../../services/global_params.service";

export default {
  name: "DevenirExposant",
  data: () => ({
    url:null,
    text:null
  }),
  created() {
    Global_paramsService.getInscriptionExposants().then(response => {
      this.url = response['data'].value.url;
      this.text = response['data'].value.text;
    });
  }
}
</script>

<style scoped>

</style>