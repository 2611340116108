<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
      <h2>Concours de cosplay</h2>
    <v-row>
    <v-col cols="12">
      <v-img
        alt="Photo Cosplay"
        class="ma-auto"
        contain
        src="../../assets/image/cosplay.jpeg"
        transition="scale-transition"
        max-width="700px"
      />
    </v-col>
      <v-col cols="12" v-html="text"></v-col>
    <v-col cols="12" class="text-center">
      <p v-for="file in files">
      <v-btn icon :href="file.url" target="_blank">
        <v-icon>mdi-download</v-icon>
      </v-btn>
        {{ file.nom }}
      </p>
    </v-col>
    </v-row>
    <v-row>
      <iframe
        :src="url"
        v-if="url"
        width="100%"
        height="1000px"
        >Chargement en cours...
      </iframe>
    </v-row>
  </v-container>
</template>

<script>
import GlobalParamsService from "../../services/global_params.service";

export default {
  name: "Cosplay",
  data: () => ({
    url:null,
    text:null,
    files:[]
  }),
  created() {
    GlobalParamsService.getConcoursCosplay().then(response => {
      this.url = response['data'].value.url;
      this.text = response['data'].value.text;
      this.files=response['data'].value.files;
    });
  }
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }
  .paraph2 {
    font-family: $font;
    span {
      color: $orange;
      font-weight: bold;
    }
  }
  .paraph-list {
    list-style: none;
    span {
      color: $orange;
      font-weight: bold;
    }
  }
}
</style>
